<template>
  <default-layout>
    <v-container id="agency-edit" fluid tag="section">
      <agency-form />
    </v-container>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import { mapState } from 'vuex';
const AgencyForm = () => import('@/components/master-data/agency/form/agency-form');

export default {
  name: 'agency-edit',
  components: {
    DefaultLayout,
    AgencyForm,
  },
  data() {
    return {
      isLoading: false,
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({}),
  },
  async created() {
    await this.$store.dispatch('agency/form/restoreInitialState');
    this.$store.commit('agency/form/SET_FORM_TYPE', 'EDIT');
    this.$store.commit('agency/form/SET_ID', this.$route.params.id);
    await this.$store.dispatch('agency/form/fetchBaseData');
  },
};
</script>
